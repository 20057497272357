import { Hero, WhoWeR, TopCompanies, Price} from "../sections/Home/index";
import Layout from '../Layout';

function Home() {
  return (
    <>
      <Layout>
      <Hero />
      <WhoWeR />
      <TopCompanies />
      <Price />
      </Layout>
    </>
  );
}

export default Home;

