import   './Lcd.css'
import shasha1 from '../../../assets/images/shasha1.png'
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// aniamtions
import WOW from 'wowjs';
import { Link } from 'react-router-dom';

function Lcd() {
    const { t } = useTranslation();
    useEffect(() => {
        const wow = new WOW.WOW({});
        wow.init();
      }, []);
      
  return (
    <div className='features-lcd-section'>
        <hr />
        <div className="content-area">
            <div className="text  wow animate__animated animate__fadeInRight">
                <p className="header-text">
                {t('lcd-ch')}
                </p>
                <div className="points">
                    <p>{t('lcd-point1')}</p>
                    <p>{t('lcd-point2')}</p>
                    <p>{t('lcd-point3')}</p>
                    <p>{t('lcd-point4')}</p>
                    <p>{t('lcd-point5')}</p>
                </div>
                <div className="button">
                    <Link to="/contact-us">{t('contact-us')}</Link>
                </div>
            </div>
            <div className="img wow animate__animated animate__fadeInLeft">
                <img src={shasha1} alt="shasha1" />
            </div>
        </div>
        <hr />
    </div>
  )
}

export default Lcd