import './Price.css'
import price from '../../../assets/images/price.png'
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// aniamtions
import WOW from 'wowjs';


function Price() {
  const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);
  
  return (
    <>
    <hr />
    <div className='price-section wow animate__animated animate__fadeInUp'>
        <img src={price} alt="price" />
        <p className="title">
          {t('price1')}
          <br />
          {t('price2')}
        </p>
        <img src={price} alt="price" />
    </div>
    <hr />
    </>
  )
}

export default Price