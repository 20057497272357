export const faqsEN = [
    {
        id: 1,
        header: "What is Line?",
        text: `It is an advertising service on screens attached to the top of taxis in Baghdad.`
    },
    {
        id: 2,
        header: "Why is advertising better than external screens?",
        text: `After studying, we found that the target customer is the customer who is on the road. We are the ones who go to him. We do not have to wait for him to raise his head to the screen.`
    },
    {
        id: 3,
        header: "How do I get a screen for my car?",
        text: `By contacting us on 07860060091 07760060091 and booking an appointment to register the car.`
    },
    {
        id: 4,
        header: "Where are the presence points?",
        text: `Because it is linked to taxis, Line screens roam during the day in all the streets of the capital, Baghdad, and its suburbs.`
    },
    {
        id: 5,
        header: "What are the advertising prices with Line?",
        text: `
        To know the prices as they are constantly updated, please contact the following numbers
        
        07860060091 07760060091
        `
    }
]


export const faqsAR = [
    {
        id: 1,
        header: "شنو هي لاين؟",
        text: `هي خدمة الإعلان على شاشات مربوطة في أعلى سيارات الأجرة في بغداد.`
    },
    {
        id: 2,
        header: "ليش الإعلان يمكم أحسن من الشاشات الخارجية؟",
        text: `بعد دراسة وجدنا ان العميل المستهدف هو العميل الذي يتواجد في الطريق. ونحن من نذهب اليه. لا ان ننتظر ان يرفع رأسه للشاشة.`
    },
    {
        id: 3,
        header: "كيف احصل على شاشة لسيارتي؟",
        text: `من خلال التواصل معنا 07860060091 07760060091 وحجز موعد لتسجيل السيارة.`
    },
    {
        id: 4,
        header: "ما هي نقاط تواجد لاين؟",
        text: `كونها مرتبطة بسيارات الأجرة فشاشات لاين تتجول خلال اليوم في جميع شوارع العاصمة بغداد وضواحيها.`
    },
    {
        id: 5,
        header: "كم هي اسعار الإعلان مع لاين؟",
        text: `لمعرفة الأسعار كونها تتحدث باستمرار، يرجى التواصل مع الأرقام التالية
        07860060091 07760060091
        `
    }
]