import './Header.css';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';


function Header() {
  const { t , i18n} = useTranslation();
  const [scrolling, setScrolling] = useState(false);

  // Add a scroll event listener using useEffect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > document.body.offsetTop) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)',
    boxShadow: scrolling
      ? '1px 1px 5px 0px rgba(255, 255, 255, 0.15)'
      : '1px 1px 5px 0px rgba(255, 255, 255, 0)',
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className='navbar' style={navbarStyle}>
         <Link className='link' to="/">{t('home')}</Link>
         <Link className='link' to="/features">{t('features')}</Link>
          <img className='logo' src={logo} alt="logo" />
          <Link className='link' to="/who-we-r">{t('who-we-r')}</Link>
          <Link className='link' to="/contact-us">{t('contact-us')}</Link>

          <div className="dropdown">
          <button className="dropdown-button"><span>{i18n.language}</span><i className="fa fa-chevron-down"></i></button>
          <div className="dropdown-content">
              <a className='lang-link'  onClick={() => changeLanguage('ar')}>AR</a>
              <a className='lang-link'   onClick={() => changeLanguage('en')}>EN</a>
          </div>
      </div>
    </nav>
  )
}

export default Header