import { useRef } from "react";
import './AccordionItem.css';

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default AccordionItem;

// const App = () => {
    
//     const [active, setActive] = useState(null);

//     const handleToggle = (index) => {
//         if (active === index) {
//             setActive(null);
//         } else {
//             setActive(index);
//         }
//     }
    
//     return (
//         <>
//             <div className="container-fluid mt-5 mb-5">
//                 <div className="row justify-content-center">
//                     <div className="col-md-8 mt-2">
//                         <div className="card">
//                             <div className="card-body">
//                               <h4 className="form-heading mb-4 text-primary text-center mt-3">React Accordion</h4>
//                                 {faqs.map((faq, index) => {
//                                      return (
//                                             <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
//                                         )
//                                     })
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// ReactDOM.render(<App />, document.getElementById("root"));
