import logo from '../../assets/images/logo.png'
import './Loading.css'


function Loading() {
  return (
<div className='global-spinner-container'>
  <img src={logo} alt="logo" className='logo' />
  <div className='spinner'>
    <div className='bar1'></div>
    <div className='bar2'></div>
    <div className='bar3'></div>
    <div className='bar4'></div>
    <div className='bar5'></div>
    <div className='bar6'></div>
    <div className='bar7'></div>
    <div className='bar8'></div>
    <div className='bar9'></div>
    <div className='bar10'></div>
    <div className='bar11'></div>
    <div className='bar12'></div>
  </div>
  {/* <div className='spinner-message'>Loading...</div> */}
</div>

    // <div className='content-area'>
    //   <img src={logo} alt="logo" />
    // </div>
  )
}

export default Loading