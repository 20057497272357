import './TopCompanies.css'
import comapniesImg from '../../../assets/images/companies.png'
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// aniamtions
import WOW from 'wowjs';

function TopCompanies() {
  const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);

  return (
    <div className='topCompanies wow animate__animated animate__fadeInDown'>
         <hr />
        <p className="title">{t('top-companies')}</p>
        <div className="group">
        <div className="images">
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        </div>
        <div className="images">
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        <img src={comapniesImg} alt="companies" />
        </div>
        </div>
    </div>
  )
}

export default TopCompanies