import { useState } from 'react';
import Layout from '../Layout';
import  AccordionItem  from "../sections/Faq/AccordionItem";
import { faqsAR, faqsEN } from '../data/faqs';
import './Faq.css';
import { useTranslation } from 'react-i18next';
import { Header } from '../components';


function Faq() {
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
  
    return (
        <Layout>
        <header className="faqs-header">
          <Header />
         <div className="faqs">
           <h2>{t('faq-title')}</h2>
           {
           currentLang === 'en' ?
           faqsEN.map((faq, index) => {
                return (
                <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
            )
            })
            :
            faqsAR.map((faq, index) => {
                return (
                <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
            )
            })
            }
         </div>
        </header>
        </Layout>
      );
}

export default Faq

