import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider ,} from 'react-i18next';
import i18n from 'i18next';
import translationEN from './locales/en.json'; 
import translationAR from './locales/ar.json';

i18n.init({
  interpolation: { escapeValue: false }, 
  lng: 'en', 
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
    <App />
    </React.StrictMode>
  </I18nextProvider>
 
);

reportWebVitals();
