import Layout from '../Layout';
import  Form  from "../sections/Contact/Form";
function Contact() {
    return (
        <Layout>
        <Form />
        </Layout>
      );
}

export default Contact

