import './NotFound.css';
import notFoundImage from '../assets/images/not_found.gif';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
   <div className="not-found">
      <div className="four_zero_four_bg">
        <h1 className="text-center ">404</h1>
        <img src={notFoundImage} alt="notFoundImage" />
      </div>
      
      <div className="contant_box_404">
      <h3 className="h2">
        Look like you're lost
      </h3>
      <p>the page you are looking for not avaible!</p>
      <Link className="link_404" to='/'>Go to Home</Link>
    </div>
   </div>
  )
}

export default NotFound