import './Form.css'
import { Header} from "../../components/index";
import 'font-awesome/css/font-awesome.min.css';
import customerImg from '../../assets/images/customer-service.png'

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// aniamtions
import WOW from 'wowjs';
// email api 
import emailjs from '@emailjs/browser';


function Form() {

  const [msg, setMsg] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setMsg('');
    emailjs.sendForm('service_a9to5tl', 'template_f62nuef', form.current, 'DVAEoEcsDnN0rXwAs')
      .then((result) => {
        setMsg('done');
        form.current.reset()
      }, (error) => {
          // console.log(error.text);
      });
  };


  return (
        <header className="contact-header">
         <Header />
         <div className="contnet-area">
            <form className="form" ref={form}  onSubmit={sendEmail}>
              <div className="inputs-group wow animate__animated animate__fadeInLeft">
                <p className="header-text">{t('c-title')}</p>
           
                <p className={msg !== ''? 'done': ''}>{msg !== '' ? t('done'): ''}</p>
              
                <p className="que">{t('c-s')}</p>
                <input type="text" placeholder={t('in-name')} name="user_name" required/>
                <input type="email" placeholder={t('in-email')} name="user_email" required/>
                <textarea name="message"  cols="30" rows="10" required placeholder={t('in-head')}></textarea>
                <button type="submit">{t('submit')}</button>
              </div>
              <div className="info wow animate__animated animate__fadeInRight">
                <img src={customerImg} alt="customer" />
                <nav>
                 <div className="group"> <i className="fa fa-map-pin" ></i><span>{t('location')}</span></div>
                 <div className="group"> <i className='fa fa-phone'></i><span>078600600091 , 077600600091 </span></div>
                <div className="group"> <i className="fa fa-envelope"></i><span>info@line.com </span></div>
                </nav>
              </div>
            </form>
        </div>
        <hr />
        </header>
  )
}

export default Form