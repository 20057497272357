import './WhoWeR.css'
import 'font-awesome/css/font-awesome.min.css'
import missionImg from '../../../assets/images/mession.png'
import strategyImg from '../../../assets/images/strategy.png'
import messageImg from '../../../assets/images/icons/message.png'
import photoImg from '../../../assets/images/icons/photo.png'
import targetImg from '../../../assets/images/icons/target.png'
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// aniamtions
import WOW from 'wowjs';


function WhoWeR() {
  const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);

  return (
    <div className="how-we-r">
       <div className="divided"> </div>
        <div className="cards first-section">
            <div className="card wow animate__animated animate__fadeInLeft">
                <span>1</span>
                 <div className="icon"><img src={messageImg} alt="messageImg" /></div>
                <div className="text">{t('h-point1')}</div>
            </div>
            <div className="card wow animate__animated animate__fadeInLeft">
               <span>2</span>
                <div className="icon"><img src={photoImg} alt="photoImg" /></div>
                <div className="text">{t('h-point2')}</div>
            </div>
            <div className="card wow animate__animated animate__fadeInLeft">
                <span>3</span>
                <div className="icon"><img src={targetImg} alt="targetImg" /></div>
                <div className="text">{t('h-point3')}</div>
            </div>
        </div>
        <hr />
        <div className="second-section">
          <p className="title"><div className="text">{t('who-we-r')}</div></p>
          <p className="sub-title">{t('who-we-r-s')}</p>
          <div className="cards">
            <div className="card  wow animate__animated animate__slideInLeft">
              <img src={missionImg} alt="mission" />
              <p className="title">{t('our-mission')}</p>
              <p className="des">{t('our-mission-text')}</p>
            </div>
            <div className="card  wow animate__animated animate__slideInRight">
              <img src={strategyImg} alt="mission" />
              <p className="title">{t('our-strategy')}</p>
              <p className="des">{t('our-strategy-text')}</p>
            </div>
          </div>
          <div className="advertising-card wow animate__animated animate__slideInUp">
              <div className="details">
              <p className="title">{t('advertising')}</p>
              <p className="subTitle">{t('advertising-s')}</p>
              <p className="des">
                {t('lcd-ch')}
                <br />
               {t('lcd-point1')}
                <br />
                {t('lcd-point2')}
                <br />
                {t('lcd-point3')}
                <br />
                {t('lcd-point4')}
              </p>
              </div>
            </div>
            <div className="how-to-contact-us wow animate__animated animate__slideInUp">
              <div className="text">
              <p className="title">{t('how-to-contact-us')}</p>
              <p className="des">{t('how-to-contact-us-text')}</p>
              </div>
             <div className="button">
             <Link  className='contact-us' to="/contact-us">{t('call-us-now')}</Link>
             </div>
            </div>
        </div>
    </div>
  )
}

export default WhoWeR