import Layout from '../Layout';
import {Hero, Lcd} from "../sections/Features/index";
function Features() {
    return (
        <Layout>
        <Hero /> 
        <Lcd />
        </Layout>
      );
}

export default Features

