import './Footer.css'
import logo from '../../assets/images/logo.png'
import 'font-awesome/css/font-awesome.min.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className='footer'>
      {/* start first */}
      <div className='first'>
      <img className='logo' src={logo} alt="logo" />
        <nav>
          <a href="#">{t('location')}</a>
          <a href="mailto:info@lineads.co">info@lineads.co</a>
          <a href="tel:078600600091">078600600091</a>
          <a href="tel:077600600091">077600600091</a>
        </nav>
      </div>
        {/* start second */}
      <div className='second'>
        <p>{t('company')}</p>
        <nav>
          <Link to="/">{t('home')}</Link>
          <Link to="/features">{t('features')}</Link>
          <Link to="/who-we-r">{t('who-we-r')}</Link>
          <Link to="/contact-us">{t('contact-us')}</Link>
        </nav>
      </div>
        {/* start third */}
      <div className='third'>
      <p>{t('policy')}</p>
        <nav>
          <a><p>{t('copyright')}</p></a>
          <a><p>{t('privacy')}</p></a>
          <a><p>{t('terms-conditions')}</p></a>
          <Link className='link' to="/faq">{t('faq-title')}</Link>
        </nav>
        </div>
        {/* start fourth */}
      <div className='fourth'>
      <p>{t('connect')}</p>
        <nav className='social-icons'>
          <a href="https://www.instagram.com/lineads.co" target="_blank" rel="noreferrer" ><i className='fa fa-instagram'></i></a>
          <a href="https://www.facebook.com/lineads.co" target="_blank" rel="noreferrer" ><i className='fa fa-facebook-square'></i></a>
          <a href="https://www.youtube.com/lineads.co" target="_blank" rel="noreferrer" ><i className='fa fa-youtube-square'></i></a>
        </nav>

      <div className='lang'>
      <p>{t('lang')}:</p>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('ar')}>العربية</button>
    </div>
    
      </div>
     </div>
    </>
   
  )
}

export default Footer