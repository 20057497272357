import Layout from '../Layout';
import Hero from "../sections/Whower/Hero";
function Whower() {
    return (
        <Layout>
        <Hero /> 
        </Layout>
      );
}

export default Whower

