// LanguageBasedCSS.js
import  { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageBasedCSS = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Determine the current language and import the corresponding CSS file
    const currentLang = i18n.language;
    const cssFileName = currentLang === 'en' ? 'en.css' : 'ar.css';
    // console.log(currentLang)

    // Dynamically import the CSS file based on the language
    import(`./assets/css/${cssFileName}`)
      .then(() => {
        // console.log(`Loaded CSS for ${currentLang}`);
      })
      .catch((error) => {
        // console.error(`Error loading CSS: ${error}`);
      });
  }, [i18n.language]);

  return null; // This component does not render anything
};

export default LanguageBasedCSS;
