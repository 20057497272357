import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { I18nextProvider , useTranslation} from 'react-i18next';
import LanguageBasedCSS from './LanguageBasedCSS';

import Home from './pages/Home';
import Features from './pages/Features';
import Contact from './pages/Contact';
import Whower from './pages/Whower';
import { Loading } from "./sections/index";




// aniamtions;
import 'animate.css';
import 'animate.css/animate.min.css';
import NotFound from './pages/NotFound';
import Faq from './pages/Faq';


const App = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className={lang === 'en'? 'DirLtr' : 'DirRtl'}>
      {isLoading ? (
        <Loading />
      ) : (
        <I18nextProvider i18n={i18n}>
        <LanguageBasedCSS/>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/who-we-r" element={<Whower />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </I18nextProvider>
       
      )}
    </div>
  );
};

export default App;
