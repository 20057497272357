import { Header} from "../../components/index";
import './Hero.css'
import advertising2 from '../.../../../assets/images/advertising2.png'
import shasha2 from '../.../../../assets/images/shasha21+.png'
import line from '../.../../../assets/images/line.png'
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
// aniamtions
import WOW from 'wowjs';

function Hero() {
    const { t } = useTranslation();
    useEffect(() => {
        const wow = new WOW.WOW({});
        wow.init();
      }, []);
      
  return (
        <header className="howwer-header">
         <Header />
         <div className="contnet-area">
            {/* first sections */}
            <div className="first-section">
                <div className="title wow animate__animated animate__fadeInLeft">
                <div className="title-line"></div>
                <p className='title-text'>{t('who-we-r')}</p>
                </div>
            
                <p className="des wow animate__animated animate__fadeInRight">{t('who-we-r-text')}</p>
                <div className="points-img">
                <div className="points">
                <div className="our-mission wow animate__animated animate__fadeInLeft">
                    <p className="header-text">{t('our-mission')}</p>
                    <p className="des">
                    {t('our-mission-text')} 
                    </p>
                </div>
                <div className="our-strategy wow animate__animated animate__fadeInRight">
                    <p className="header-text">{t('our-strategy')}</p>
                    <p className="des">
                    {t('our-strategy-text')}
                    </p>
                </div>
                </div>
                <div className="img wow animate__animated animate__fadeInUp">
                    <img src={advertising2} alt="advertising2" />
                </div>
                </div>
            </div>
            <hr />
            {/* second sections */}
            <div className="second-section">
                <div className="text wow animate__animated animate__fadeInUp">
                    <p className="header-text">{t('how-do-we-work')}</p>
                    <p className="des">
                    {t('how-do-we-work-text')} 
                    </p>
                </div>
                <div className="images wow animate__animated animate__fadeInUp">
                <img src={shasha2} alt="advertising2" />
                <img src={line} alt="advertising2" />
                </div>
            </div>
            <hr />
        </div>
        </header>
  )
}

export default Hero