import './Hero.css'
import { Header} from "../../../components/index";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// aniamtions
import WOW from 'wowjs';

function Hero() {
  const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);

  return (
   <header className='home-header'>
      <Header />
      <div className="contnet-area">
        <p className='title wow animate__animated animate__fadeInDown animate__delay-1s'>{t('spotilighted-2')}</p>
        <h2 className="sub-title wow animate__animated animate__fadeInLeft">{t('spotilighted-1')}</h2>
        
        <p className="des wow animate__animated animate__fadeInUp animate__delay-1s">
         {t('h-des1')}
         <br />
         {t('h-des2')}
       <br />
       {t('h-des3')}
        </p>
    <div className="buttons wow animate__animated animate__fadeInUp animate__delay-2s">
      <Link  className='features' to="/features">{t('features')}</Link>
      <Link  className='contact-us' to="/contact-us">{t('contact-us')}</Link>
    </div>
      </div>
   </header>
  )
}

export default Hero