import { Header} from "../../../components/index";
import './Hero.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// aniamtions
import WOW from 'wowjs';

function Hero() {
 const { t } = useTranslation();
  useEffect(() => {
    const wow = new WOW.WOW({});
    wow.init();
  }, []);
  
  return (
        <header className="features-header">
         <Header />
         <div className="contnet-area">
        <div className="title wow animate__animated animate__fadeInLeft">
          <div className="title-line"></div>
        <p className='title-text'>{t('features')}</p>
        </div>
        
        <p className="des wow animate__animated animate__fadeInRight">
        {t('features-text')}
        </p>
        <div className="points  wow animate__animated animate__fadeInLeft">
          <p>{t('point1')}</p>
          <p>{t('point2')}</p>
          <p>{t('point3')}</p>
          <p>{t('point4')}</p>
        </div>
        </div>
        </header>
  )
}

export default Hero